.header {
  background: white;
  z-index: 20;
  height: 55px;
}
.header:after {
  content: "";
  width: 100%;
  height: 50px;
  bottom: -50px;
  position: absolute;
  left: 0;
  display: inline-block;
  background: url("/assets/border.png");
  z-index: 1;
  background-position-y: 0;
  background-size: contain;
  background-repeat: repeat-x;
}
/* 
@media (min-width: 1280px) {
  .header:after {
    bottom: -48px;
  }
}
 */
